$( document ).ready(function() {
    _herobanner();
});
const _herobanner = () => {
    if ( $('.js-herobanner-carousel').length > 0 ) {

        $('.js-herobanner-carousel').each( function (i) {

            var $_herobanner_carousel = $(this);
            $_herobanner_carousel.not('.slick-initialized').slick({
                lazyLoad: "ondemand",
                slidesToShow: 1,
                slidesToScroll: 1,
                dots: true,
                autoplay: true,
                autoplaySpeed: 8000,
                fade: true,
                cssEase: 'linear',
                appendArrows: $_herobanner_carousel.closest('.herobanner').find('.herobanner-control .slick-arrows-wrapper'),
                appendDots: $_herobanner_carousel.closest('.herobanner').find('.herobanner-control .slick-dots-wrapper'),
                prevArrow: '<button type="button" class="slick-prev"><span class="sr-only">Go to previous slide</span></button>',
                nextArrow: '<button type="button" class="slick-next"><span class="sr-only">Go to next slide</span></button>'
            });

            $_herobanner_carousel.on('afterChange', function(event, slick, currentSlide) {
                let _slide = $(slick.$slides[currentSlide]),
                    _color = _slide.attr('data-color');
                _slide.closest('.herobanner').attr('data-color', _color);
            });
            
            $('.herobanner .button-play').on('click', function(e) {
                e.preventDefault();
                var $_playButton = $(this);
                $_playButton.toggleClass('play');
                if ( $_playButton.hasClass('play') ) {
                    $_herobanner_carousel.slick('slickPlay');
                }
                else {
                    $_herobanner_carousel.slick('slickPause');
                }
            });
        });

    }
}